import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui'
import { ReturnsReport } from '@/modules/merchant/reports/returns-report.tsx'
import { TransactionsReport } from '@/modules/merchant/reports/transactions-report'

export function MerchantReportsPage() {
	return (
		<div className="container flex w-full flex-col gap-md p-4 xl:p-8">
			<h1 className="typography-headline-medium text-primary">Reports</h1>
			<Tabs defaultValue="transactions">
				<TabsList className="grid w-full grid-cols-2 bg-white">
					<TabsTrigger
						className="data-[state=active]:bg-primary data-[state=active]:text-white"
						value="transactions"
					>
						Transactions Report
					</TabsTrigger>
					<TabsTrigger
						className="data-[state=active]:bg-primary data-[state=active]:text-white"
						value="returns"
					>
						Returns Report
					</TabsTrigger>
				</TabsList>
				<TabsContent value="transactions">
					<TransactionsReport />
				</TabsContent>
				<TabsContent value="returns">
					<ReturnsReport />
				</TabsContent>
			</Tabs>
		</div>
	)
}
