import { useQueryClient } from '@tanstack/react-query'
import { KyInstance } from 'ky'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { PlaidLinkOnEvent, PlaidLinkOnExit, PlaidLinkOnSuccess, PlaidLinkOptions, usePlaidLink } from 'react-plaid-link'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { useInterval } from 'usehooks-ts'

import {
	ExchangePublicTokenReq,
	ExternalAccountAccessTokenResp,
	PlaidLinkTokenResp,
	UpdateLinkTokenReq,
} from '../../@types/plaid'
import { LINK_TYPE } from '../../constants/link-type.ts'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

async function createLinkToken(httpInstance: KyInstance, updateLinkTokenReq?: UpdateLinkTokenReq) {
	const response = await httpInstance
		.post('external_accounts/link_token', {
			json: {
				type: LINK_TYPE,
				...updateLinkTokenReq,
			},
		})
		.json<PlaidLinkTokenResp>()
	return response
}

async function exchangePublicToken(httpInstance: KyInstance, exchangePublicTokenReq: ExchangePublicTokenReq) {
	const response = await httpInstance
		.post('external_accounts/access_token', {
			json: {
				...exchangePublicTokenReq,
			},
		})
		.json<ExternalAccountAccessTokenResp>()
	return response
}

type usePlaidTokenProps = {
	redirectTo?: string | null
	returnTo?: string | null
	vendor_access_token?: string | null
	vendor_institution_id?: string | null
}

export function usePlaidToken(props?: usePlaidTokenProps) {
	const { paymentServiceClient } = usePaymentService()
	const [token, setToken] = useState<PlaidLinkTokenResp>()
	const [loading, setLoading] = useState(false)
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const [linkTokenError, setLinkTokenError] = useState<Error | null>(null)

	const createToken = useCallback(() => {
		setLoading(true)
		createLinkToken(
			paymentServiceClient.httpInstance,
			props?.vendor_access_token && props?.vendor_institution_id
				? { vendor_access_token: props.vendor_access_token, vendor_institution_id: props.vendor_institution_id }
				: undefined,
		)
			.then((data) => {
				setToken(data)
				setLinkTokenError(null)
			})
			.catch((err) => {
				console.error(err)
				setLinkTokenError(err)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [paymentServiceClient.httpInstance, props?.vendor_access_token, props?.vendor_institution_id])

	const onSuccess = useCallback<PlaidLinkOnSuccess>(
		async (publicToken, metadata) => {
			setLoading(true)
			if (!metadata.institution || metadata.accounts.length < 1) {
				setLoading(false)
				throw new Error('No accounts returned from Plaid Link.')
			}
			const subtype = 'checking'
			const type = 'depository'
			const accounts = metadata.accounts.filter((acc) => acc.subtype === subtype && acc.type === type)

			if (!accounts.length) {
				navigate(`/link-account-error${props?.returnTo ? `?returnTo=${props.returnTo}` : ''}`)
				return
			}

			const exchangePublicTokenReq: ExchangePublicTokenReq = {
				vendor_public_token: publicToken,
				vendor_institution_id: metadata.institution.institution_id,
				vendor_institution_name: metadata.institution.name,
				accountIds: accounts.map((account) => account.id),
				accountMasks: accounts.map((account) => account.mask),
				vendor_access_token: props?.vendor_access_token,
			}

			const response = await exchangePublicToken(paymentServiceClient.httpInstance, exchangePublicTokenReq).catch(
				(err) => {
					setLoading(false)
					throw err
				},
			)
			queryClient.removeQueries({ queryKey: ['user'] })
			await queryClient.invalidateQueries({ queryKey: ['purchase_power'] })
			if (response.vendor_access_token) {
				setLoading(false)
				navigate(props?.redirectTo || '/dashboard/accounts')
			}
			toast.success('Success!', {
				description: 'Your bank account is linked',
				action: {
					label: 'Close',
					onClick: () => {},
				},
			})
		},
		[
			navigate,
			paymentServiceClient.httpInstance,
			props?.redirectTo,
			props?.returnTo,
			props?.vendor_access_token,
			queryClient,
		],
	)

	const onEvent = useCallback<PlaidLinkOnEvent>((eventName) => {
		// Only set loading to true for certain events
		if (eventName === 'OPEN') {
			setLoading(true)
		}
	}, [])

	const onExit = useCallback<PlaidLinkOnExit>(
		(error, metadata) => {
			setLoading(false)
			console.error({ error, metadata })
			if (error) {
				createToken()
			}
		},
		[createToken],
	)

	const config: PlaidLinkOptions = useMemo(
		() => ({
			onSuccess,
			onEvent,
			onExit,
			token: token?.link_token || '',
		}),
		[onEvent, onExit, onSuccess, token?.link_token],
	)
	const { open, ready, error } = usePlaidLink(config)
	const handleOpen = useCallback(() => {
		setLoading(true)
		open()
	}, [open])

	useEffect(() => {
		createToken()
	}, [createToken])

	// it is running every seconds. If the token is about to 1 minute to expire, it will create a new token. The current token is valid for 4 hours.
	useInterval(() => {
		if (token?.expiration) {
			const secondsToExpire = Math.round((new Date(token.expiration).getTime() - Date.now()) / 1000)
			if (secondsToExpire < 60) {
				createToken()
			}
		}
	}, 10000)

	return { open: handleOpen, ready, token, error, loading, linkTokenError }
}
