import { HTTPError } from 'ky'
import { ArrowLeftIcon } from 'lucide-react'
import { useCallback, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { ErrorCode, ErrorResponse } from '@/api/errors.ts'
import { CreatePurchaseRequest, useCreatePayment } from '@/api/hooks/use-payment'
import BodyClassName from '@/components/body-class-name'
import { Loading } from '@/components/loading'
import ThemeColor from '@/components/theme-color'
import { Button, Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui'
import { CheckoutEnterCode } from '@/modules/merchant/checkout/enter-code'
import { ReadQrCode } from '@/modules/merchant/checkout/read-qr-code'
import { useMerchantCheckoutStore } from '@/store/merchant/merchantCheckoutStore'

export function MerchantCheckoutCodePage() {
	const { mutate } = useCreatePayment()
	const purchase = useMerchantCheckoutStore((state) => state.purchase)
	const setPurchaseResponse = useMerchantCheckoutStore((state) => state.setPurchaseResponse)
	const setError = useMerchantCheckoutStore((state) => state.setError)

	const [loading, setLoading] = useState(false)

	const navigate = useNavigate()

	const handleError = useCallback(
		({ err, path }: { err?: string; path?: string }) => {
			setLoading(false)
			setError(err || 'An error occurred')
			navigate(path || '/merchant/checkout/error')
		},
		[navigate, setError],
	)

	const handleHTTPError = useCallback(
		async (err: HTTPError) => {
			const res = await err.response?.json()
			const parsedErr = ErrorResponse.safeParse(res)
			if (parsedErr.success) {
				if (parsedErr.data.code === ErrorCode.ErrInsufficientFunds) {
					handleError({ err: parsedErr.data.message, path: '/merchant/checkout/insufficient-funds' })
				} else {
					handleError({ err: parsedErr.data.message })
				}
			} else {
				handleError({ err: err.message })
			}
		},
		[handleError],
	)

	const handlePayment = ({ purchaseId, paymentCode }: Pick<CreatePurchaseRequest, 'purchaseId' | 'paymentCode'>) => {
		if (!purchase) return

		setLoading(true)
		mutate(
			{
				purchaseId,
				paymentCode,
				amount: purchase?.price,
				notes: purchase?.notes,
				pos_order_id: purchase?.posOrderId,
				employee_name: purchase?.employeeName,
				channel: purchase?.channel,
			},
			{
				onSuccess: (data) => {
					setLoading(false)
					if (data) {
						setPurchaseResponse(data)
						navigate('/merchant/checkout/awaiting')
					} else {
						handleError({ err: 'An error occurred', path: '/merchant/checkout/error' })
					}
				},
				onError: async (error) => {
					if (error instanceof HTTPError) {
						await handleHTTPError(error)
					} else {
						handleError({})
					}
				},
			},
		)
	}

	const handleScanPayment = (data: string) => {
		const purchaseId = data.split('/').pop()
		handlePayment({
			purchaseId,
		})
	}

	const handlePaymentCode = (paymentCode: string) => {
		handlePayment({
			paymentCode,
		})
	}

	// this is needed to mimic the QR Code read in the E2E test
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	;(window as any).onScan = handleScanPayment

	return (
		<BodyClassName className="bg-neutral-50">
			<ThemeColor className="bg-neutral-50" />
			{loading ? (
				<Loading text="Wait a moment..." />
			) : (
				<div
					data-testid="checkout-code"
					className="relative flex h-full w-full"
				>
					<div className="absolute left-0 top-0 z-40 flex w-full items-center justify-center px-4">
						<div className="w-full max-w-md py-4">
							<Button
								asChild
								variant="tertiary"
								size="icon"
								className="rounded-full"
							>
								<Link
									replace
									to="/merchant/checkout"
								>
									<ArrowLeftIcon />
								</Link>
							</Button>
						</div>
					</div>

					<Tabs
						defaultValue="qr"
						className="h-full w-full"
					>
						<div className="relative z-40 mt-20 flex w-full justify-center px-4">
							<div className="w-full max-w-md px-4">
								<TabsList className="mx-auto grid w-full max-w-md grid-cols-2">
									<TabsTrigger value="qr">Scan QR Code</TabsTrigger>
									<TabsTrigger value="code">Enter Payment Code</TabsTrigger>
								</TabsList>
							</div>
						</div>

						<TabsContent value="qr">
							<ReadQrCode callback={handleScanPayment} />
						</TabsContent>
						<TabsContent value="code">
							<CheckoutEnterCode callback={handlePaymentCode} />
						</TabsContent>
					</Tabs>
				</div>
			)}
		</BodyClassName>
	)
}
