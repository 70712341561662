import { HouseIcon, MessageCircleQuestion, ShoppingCartIcon, TableIcon, UsersIcon } from 'lucide-react'
import { Link, useLocation } from 'react-router-dom'

import {
	Sidebar,
	SidebarContent,
	SidebarGroup,
	SidebarGroupContent,
	SidebarMenu,
	SidebarMenuButton,
	SidebarMenuItem,
} from '@/components/ui/sidebar'

const items = [
	{
		title: 'Home',
		url: '/merchant/home',
		icon: HouseIcon,
		exactUrl: true,
	},
	{
		title: 'Checkout',
		url: '/merchant/checkout',
		icon: ShoppingCartIcon,
	},
	{
		title: 'Reports',
		url: '/merchant/report',
		icon: TableIcon,
	},
	{
		title: 'Users',
		url: '/merchant/users',
		icon: UsersIcon,
	},
	{
		title: 'Support',
		url: '/merchant/support',
		icon: MessageCircleQuestion,
	},
]

export function MerchantNavigation() {
	const location = useLocation()

	return (
		<Sidebar
			collapsible="icon"
			className="mt-header"
		>
			<SidebarContent>
				<SidebarGroup className="py-4">
					<SidebarGroupContent>
						<SidebarMenu className="group-data-[collapsible=icon]:gap-10">
							{items.map((item) => {
								const isActive = item.exactUrl ? item.url === location.pathname : location.pathname.startsWith(item.url)
								return (
									<SidebarMenuItem key={item.title}>
										<SidebarMenuButton
											asChild
											isActive={isActive}
											className="group-data-[collapsible=icon]:[&>span]:typography-label-medium-prominent [&>span]:left-0 [&>span]:top-full [&>span]:w-full group-data-[collapsible=icon]:[&>span]:absolute group-data-[collapsible=icon]:[&>span]:pt-1 group-data-[collapsible=icon]:[&>span]:text-center"
										>
											<Link to={item.url}>
												<item.icon />
												<span>{item.title}</span>
											</Link>
										</SidebarMenuButton>
									</SidebarMenuItem>
								)
							})}
						</SidebarMenu>
					</SidebarGroupContent>
				</SidebarGroup>
			</SidebarContent>
		</Sidebar>
	)
}
