import { Link, Navigate } from 'react-router-dom'

import { ErrorWrapper } from '@/components/error/error-wrapper.tsx'
import { Button } from '@/components/ui'
import { useMerchantCheckoutStore } from '@/store/merchant/merchantCheckoutStore'

export function MerchantCheckoutCancelled() {
	const purchase = useMerchantCheckoutStore((state) => state.purchase)
	const reset = useMerchantCheckoutStore((state) => state.reset)

	if (!purchase) {
		return <Navigate to="/merchant/checkout" />
	}

	return (
		<ErrorWrapper
			title="Transaction Cancelled"
			description="The transaction was cancelled."
		>
			<Button
				asChild
				className="mt-auto w-full md:mt-8"
				variant="destructive"
			>
				<Link
					to="/merchant/checkout"
					onClick={reset}
				>
					Back To Checkout
				</Link>
			</Button>
		</ErrorWrapper>
	)
}
