import { DeclinedTransactions } from '@/@types/merchant'
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader } from '@/components/ui'
import { dateTimeFormatter } from '@/utils/dates.ts'
import { formatCentsToCurrency } from '@/utils/money-utils.ts'

type ResolveDialogProps = {
	onCancel: () => void
	transactionDetails: DeclinedTransactions
	open: boolean
	onOpenChange: (open: boolean) => void
}

export const TransactionDetail = ({ onCancel, transactionDetails, open, onOpenChange }: ResolveDialogProps) => {
	return (
		<Dialog
			open={open}
			onOpenChange={onOpenChange}
		>
			<DialogContent>
				<DialogHeader>
					<h1 className="typography-title-large text-center">Transaction Details</h1>
				</DialogHeader>
				<div className="w-full rounded-sm bg-primary-50 px-md py-lg">
					<h2 className="typography-label-large-prominent">Transaction Details</h2>
					<div className="my-4 h-[1px] w-full bg-primary-400" />
					<div className="grid grid-cols-2 gap-x-md gap-y-sm">
						<span className="typography-label-large-prominent">Transaction ID</span>
						<span className="typography-body-medium">{transactionDetails.public_id}</span>
						<span className="typography-label-large-prominent">Transaction Date</span>
						<span className="typography-body-medium">
							{dateTimeFormatter.format(new Date(transactionDetails.date_time_created))}
						</span>
						<span className="typography-label-large-prominent">Total Price</span>
						<span className="typography-body-medium">{formatCentsToCurrency(transactionDetails.base_amount)}</span>
						<span className="typography-label-large-prominent">Customer Name</span>
						<span className="typography-body-medium">{transactionDetails.customer_name}</span>
						<span className="typography-label-large-prominent">Customer Email</span>
						<span className="typography-body-medium">{transactionDetails.customer_email}</span>
						<span className="typography-label-large-prominent">Customer Phone</span>
						<span className="typography-body-medium">{transactionDetails.customer_phone}</span>
					</div>
				</div>
				<DialogFooter>
					<Button
						className="w-full"
						onClick={onCancel}
						type="button"
					>
						Close
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
