import { useAuth0 } from '@auth0/auth0-react'
import { LogOutIcon, UserIcon, HelpCircleIcon, UserMinusIcon } from 'lucide-react'

import { useUser } from '@/api/hooks/use-user.ts'
import { Loading } from '@/components/loading'
import { Button } from '@/components/ui'
import { DashboardLayout } from '@/modules/consumer/dashboard/dashboard-layout.tsx'
import { SettingsItem } from '@/modules/consumer/settings/settings-item.tsx'
import { useLogoutOptions } from '@/utils/logout-utils.ts'

export function SettingsPage() {
	const { logout, isLoading } = useAuth0()
	const { data: dbUser, isLoading: loadingUser } = useUser()
	const logoutOptions = useLogoutOptions()

	if (isLoading || loadingUser) {
		return <Loading />
	}

	return (
		<DashboardLayout>
			<div className="mx-auto max-w-2xl">
				<div className="mb-8">
					<h1 className="typography-headline-medium text-primary-500">Settings</h1>
					<p className="typography-body-medium text-neutral-700">Manage your account settings and preferences</p>
				</div>

				<div className="space-y-3">
					<SettingsItem
						title={`${dbUser?.first_name} ${dbUser?.last_name}`}
						subtitle="Personal Information"
						href={'/dashboard/settings/personal-information'}
						testId="personal-information"
						icon={<UserIcon className="size-5" />}
					/>
					<SettingsItem
						title={'Contact Support'}
						href={'/dashboard/settings/contact-support'}
						testId="contact-support"
						icon={<HelpCircleIcon className="size-5" />}
					/>
					<SettingsItem
						title={'Deactivate Account'}
						href={'/dashboard/settings/deactivate-account'}
						testId="deactivate-account"
						icon={<UserMinusIcon className="size-5" />}
					/>
				</div>

				<div className="mt-8">
					<Button
						className="w-full"
						variant="destructive"
						onClick={() => logout(logoutOptions)}
						data-testid="logout-button"
					>
						<LogOutIcon className="size-5" /> Log Out
					</Button>
				</div>
			</div>
		</DashboardLayout>
	)
}
