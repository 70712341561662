import { BadgeDollarSign } from 'lucide-react'

import success from '@/assets/images/success.svg'
import { Button } from '@/components/ui'
import { cn } from '@/lib/utils'
import { dateFormatter, timeFormatter } from '@/utils/dates'
import { formatCentsToCurrency } from '@/utils/money-utils.ts'

type PaymentSuccessProps = {
	amount: number
	publicId?: string
	timestamp: string
	onContinue?: () => void
	continueText?: string
	className?: string
}

export const PaymentSuccess = ({
	amount,
	publicId,
	timestamp,
	onContinue,
	continueText = 'Continue',
	className,
}: PaymentSuccessProps) => {
	const date = new Date(timestamp)

	return (
		<div className={cn('flex h-full flex-col', className)}>
			<div className="flex flex-1 flex-col text-primary-900">
				<img
					src={success}
					alt=""
					className="mt-auto size-20 self-center"
				/>
				<h1 className="typography-display-medium mt-4 text-center text-success">Success!</h1>
				<p className="typography-body-large mt-2 text-center">Your transaction has been approved.</p>
				<div className="mb-auto mt-12 flex flex-col items-center justify-center space-y-2 rounded-md border border-primary-300 p-lg">
					<BadgeDollarSign />
					<p className="typography-title-medium">Transaction Details</p>
					<p
						className="typography-display-small text-primary"
						data-testid="order-details-value-amount"
					>
						{formatCentsToCurrency(amount)}
					</p>
					{publicId && (
						<p
							className="typography-body-medium"
							data-testid="order-details-value-id"
						>
							ID {publicId}
						</p>
					)}
					<p
						className="typography-body-medium"
						data-testid="order-details-value-date"
					>
						{dateFormatter.format(date)}, {timeFormatter.format(date)}
					</p>
				</div>
			</div>
			<div className="mt-auto flex flex-col gap-sm">
				<Button
					variant="success"
					className="w-full"
					onClick={onContinue}
				>
					{continueText}
				</Button>
			</div>
		</div>
	)
}
