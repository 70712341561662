import React, { useMemo } from 'react'

import { DetailsCard } from './details-card'

type OrderDetailsProps = Omit<React.ComponentProps<typeof DetailsCard>, 'title' | 'details'> & {
	storeName: string
	transactionDate: string
	posOrderId?: string | null
	totalPrice: string
	notes?: string
	customerName?: string | null
	transactionId?: string | null
}

export function OrderDetails({
	storeName,
	transactionDate,
	posOrderId,
	totalPrice,
	notes,
	customerName,
	transactionId,
	...props
}: OrderDetailsProps) {
	const details = useMemo(
		() => [
			{ label: 'Store Name', value: storeName },
			{ label: 'Transaction Date', value: transactionDate },
			{ label: 'POS Order ID', value: posOrderId || '-' },
			{ label: 'Total Price', value: totalPrice },
			{ label: 'Notes', value: notes || '-' },
			{ label: 'Customer Name', value: customerName },
			{ label: 'Transaction ID', value: transactionId },
		],
		[customerName, notes, posOrderId, storeName, totalPrice, transactionDate, transactionId],
	)

	return (
		<DetailsCard
			title="Order Details"
			details={details}
			{...props}
		/>
	)
}
