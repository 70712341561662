import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '@tanstack/react-query'
import { KyInstance } from 'ky'

import { DeactivateAccount } from '@/@types/deactivate-account'
import { useAlert } from 'src/lib/useAlert.tsx'
import { getKyError } from 'src/utils/fetch-utils.ts'
import { useLogoutOptions } from 'src/utils/logout-utils.ts'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

function deactivateAccount(httpInstance: KyInstance, body: DeactivateAccount) {
	return httpInstance
		.delete(`users`, {
			json: body,
		})
		.json<string>()
}
export function useDeactivateAccount() {
	const { paymentServiceClient } = usePaymentService()
	const { logout } = useAuth0()
	const { triggerSnackbar } = useAlert()
	const logoutOptions = useLogoutOptions()

	return useMutation<string, Error, DeactivateAccount, string>({
		mutationFn: async (body: DeactivateAccount) => {
			return deactivateAccount(paymentServiceClient.httpInstance, body)
		},
		onSuccess: async () => {
			await logout(logoutOptions)
		},
		onError: async (error) => {
			console.error(error)
			const errorMessage = await getKyError(error)
			triggerSnackbar(errorMessage || 'Deactivate account failed', 'error')
		},
	})
}
