import { UseMutateFunction } from '@tanstack/react-query'
import { RotateCw, X } from 'lucide-react'
import { QRCodeSVG } from 'qrcode.react'
import { useNavigate } from 'react-router-dom'

import { InitialPurchaseResponse } from '@/api/hooks/use-purchase'
import Logo from '@/assets/images/p-logo.png'
import { Button, Card, CardContent, CardHeader, Skeleton } from '@/components/ui'
import { cn } from '@/lib/utils'

type PaymentQRProps = {
	purchase?: InitialPurchaseResponse
	qrCodeExpired: boolean
	addPurchase: UseMutateFunction<InitialPurchaseResponse>
	isLoading: boolean
}

export const PaymentQr = ({ purchase, qrCodeExpired, addPurchase, isLoading }: PaymentQRProps) => {
	const navigate = useNavigate()

	const goToDashboard = () => {
		navigate('/dashboard')
	}

	return (
		<>
			<Card className="mb-4 mt-auto w-full max-w-md rounded-2xl">
				<CardHeader className="rounded-t-2xl bg-primary-50 text-center">
					<span className="typography-title-large text-primary">Show Merchant QR Code</span>
				</CardHeader>
				<CardContent className="relative w-full p-4">
					{isLoading && (
						<div className="absolute left-0 top-0 h-full w-full p-4">
							<Skeleton className="h-full w-full" />
						</div>
					)}
					<QRCodeSVG
						includeMargin
						className={cn('h-full w-full', isLoading && 'invisible')}
						value={`${location.protocol}//${location.host}/merchant/payment/${purchase?.ID}`}
						size={235}
						level="H"
						imageSettings={{
							src: Logo,
							height: 50,
							width: 50,
							excavate: true,
						}}
					/>
					{qrCodeExpired && (
						<div className="absolute inset-0 flex items-center justify-center rounded-2xl bg-white bg-opacity-30 backdrop-blur-md">
							<div
								onClick={() => addPurchase()}
								className="flex h-28 w-28 cursor-pointer flex-col items-center justify-center rounded-full bg-white bg-opacity-90"
							>
								<RotateCw className="h-16 w-16 text-[#003366]" />
								<span className="mt-[-4px] text-base font-bold text-[#003366]">Refresh</span>
							</div>
						</div>
					)}
				</CardContent>
			</Card>
			<Card className="flex w-full max-w-80 flex-col items-center justify-center rounded-2xl bg-primary-50 p-md text-primary">
				<span className="text-label-large">Payment Code:</span>
				<div className="relative">
					{isLoading && (
						<div className="absolute left-0 top-0 h-full w-full">
							<Skeleton className="h-full w-full" />
						</div>
					)}
					<span
						className={cn('typography-title-large text-center', isLoading && 'invisible')}
						data-testid="payment-code"
					>
						{purchase?.PublicId.substring(purchase?.PublicId.length - 5) || '00000'}
					</span>
				</div>
			</Card>
			<Button
				onClick={goToDashboard}
				className="mt-auto"
				type="button"
				variant="secondary"
				size="icon"
			>
				<X />
			</Button>
		</>
	)
}
