import { Link } from 'react-router-dom'

import { ErrorWrapper } from '@/components/error/error-wrapper.tsx'
import { Button } from '@/components/ui'

export const PaymentFail = () => {
	return (
		<ErrorWrapper description="Your transaction didn’t go through, Please try again.">
			<Button
				asChild
				variant="destructive"
				className="w-full"
			>
				<Link to="/dashboard">Back To Home</Link>
			</Button>
		</ErrorWrapper>
	)
}
