import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

import { auth0LinkAccountContext, auth0LinkAccountRedirectKey } from './auth0-link-account'

export const Auth0LinkAccountProvider = ({ children }: { children: React.ReactNode }) => {
	const navigate = useNavigate()

	const redirect_uri = `${window.location.origin}/registration?${auth0LinkAccountRedirectKey}`

	return (
		<Auth0Provider
			context={auth0LinkAccountContext}
			domain={import.meta.env.VITE_AUTH0_DOMAIN}
			clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
			onRedirectCallback={(appState: AppState | undefined) => {
				navigate(appState?.returnTo || window.location.pathname)
			}}
			useRefreshTokens={true}
			skipRedirectCallback={!window.location.href.includes(`${auth0LinkAccountRedirectKey}`)}
			authorizationParams={{
				redirect_uri,
				audience: import.meta.env.VITE_AUTH0_AUDIENCE,
			}}
		>
			{children}
		</Auth0Provider>
	)
}
