import { Info } from 'lucide-react'
import { useState } from 'react'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui'

export function SpendingLimitTooltip() {
	const [open, setOpen] = useState(false)

	const handleTooltipOpen = () => {
		setOpen(true)
		setTimeout(() => {
			setOpen(false)
		}, 3000)
	}

	return (
		<TooltipProvider>
			<Tooltip
				open={open}
				onOpenChange={setOpen}
			>
				<TooltipTrigger asChild>
					<span
						className="p-0"
						onClick={handleTooltipOpen}
					>
						<Info className="h-4 w-4" />
					</span>
				</TooltipTrigger>
				<TooltipContent>
					<p>Your spending limit is calculated based on your account balance, up to $500.</p>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}
