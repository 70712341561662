import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat.js'
import timezone from 'dayjs/plugin/timezone.js'
import utc from 'dayjs/plugin/utc.js'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

export const dateFormatter = new Intl.DateTimeFormat('en-US', {
	year: 'numeric',
	month: '2-digit',
	day: '2-digit',
})

export const timeFormatter = new Intl.DateTimeFormat('en-US', {
	hour: 'numeric',
	minute: 'numeric',
	hour12: true,
})

class CustomDateTimeFormatter {
	private formatter: Intl.DateTimeFormat

	constructor() {
		this.formatter = new Intl.DateTimeFormat('en-US', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		})
	}

	format(date: Date): string {
		return this.formatter.format(date).replace(',', '')
	}
}

export const dateTimeFormatter = new CustomDateTimeFormatter()

export { dayjs }
