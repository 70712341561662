import { Link } from 'react-router-dom'

import { ErrorWrapper } from '@/components/error/error-wrapper.tsx'
import { Button } from '@/components/ui'

export function PaymentInsufficientFundsPage() {
	return (
		<ErrorWrapper
			title="The order amount exceeds your daily spending limit."
			description="Please link a different bank account or use a different payment method."
		>
			<Button
				asChild
				variant="destructive"
				className="w-full"
			>
				<Link to="/dashboard">Back To Home</Link>
			</Button>
		</ErrorWrapper>
	)
}
