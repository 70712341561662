import { ArrowRight } from 'lucide-react'
import { Link } from 'react-router-dom'

type SettingsItemProps = {
	href: string
	title: string
	subtitle?: string
	testId?: string
	icon?: React.ReactNode
}

export const SettingsItem = ({ href, title, subtitle, testId, icon }: SettingsItemProps) => {
	return (
		<Link
			to={href}
			className="group flex h-[72px] cursor-pointer items-center justify-between rounded-lg border border-neutral-200 bg-white px-4 py-3 transition-all duration-200 ease-in-out hover:border-primary-200 hover:bg-primary-50/50 hover:shadow-sm"
			data-testid={`settings-item-${testId || title.toLowerCase().replace(/\s+/g, '-')}`}
		>
			<div className="flex items-center gap-3">
				{icon && <div className="flex h-10 w-10 items-center justify-center rounded-full bg-primary-50 text-primary-500 group-hover:bg-primary-100">{icon}</div>}
				<div className="flex flex-col">
					<span className="typography-title-medium text-neutral-900">{title}</span>
					{subtitle && <span className="typography-body-medium text-neutral-700">{subtitle}</span>}
				</div>
			</div>

			<ArrowRight className="text-2xl text-neutral-500 transition-transform duration-200 group-hover:translate-x-0.5 group-hover:text-primary-500" />
		</Link>
	)
}
