import { SpendingLimitTooltip } from '@/components/spending-limit-tooltip'

export function SpendingLimitInfo() {
	return (
		<div className="flex items-center gap-1">
			<span className="typography-label-large">SPENDING LIMIT</span>
			<SpendingLimitTooltip />
		</div>
	)
}
