import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { KyInstance } from 'ky'
import { toast } from 'sonner'

import { usePaymentService } from '../services/providers/payment-service-provider'

export type EcommercePurchaseRequest = {
	id: string
}

export type EcommercePurchaseResponse = {
	id: string
	amount: number
	expiration: string
	created_at: string
	updated_at: string
	order_id: string
	street: string
	unit: string
	city: string
	state: string
	postal_code: string
	purchase_id: string
}

async function getPurchase(httpInstance: KyInstance, { id }: EcommercePurchaseRequest) {
	return await httpInstance.get(`ecommerce/purchase/${id}`).json<EcommercePurchaseResponse>()
}

export function useEcommercePurchase(request: EcommercePurchaseRequest) {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()

	return useQuery({
		queryKey: ['ecommercePurchase', request],
		queryFn: async () => getPurchase(paymentServiceClient.httpInstance, request),
		enabled: !isLoading && isAuthenticated && !!request.id,
	})
}

async function acceptPurchase(httpInstance: KyInstance, request: EcommercePurchaseRequest) {
	return await httpInstance.post(`ecommerce/accept`, { json: request }).json<EcommercePurchaseResponse>()
}

export function useAcceptEcommercePurchase() {
	const queryClient = useQueryClient()
	const { paymentServiceClient } = usePaymentService()
	return useMutation({
		mutationFn: async (request: EcommercePurchaseRequest) => acceptPurchase(paymentServiceClient.httpInstance, request),
		onSuccess: (purchase, { id }) =>
			queryClient.setQueryData<EcommercePurchaseResponse>(['ecommercePurchase', { id }], purchase),
		onError: () => {
			toast.error("We're sorry, but something went wrong. Please try again later.")
		},
	})
}
