import { createColumnHelper, Row } from '@tanstack/react-table'
import { EllipsisVertical, FileText, Pen } from 'lucide-react'
import { useCallback, useMemo, useState } from 'react'

import { DeclinedTransactions } from '@/@types/merchant'
import { useCreateReturn, useResolveReturn, useReturns } from '@/api/hooks/use-returns.ts'
import { DataTable, DataTableColumnHeader } from '@/components/data-table'
import { DataTableColumnExpandButton } from '@/components/data-table/data-table-column-expand-button'
import {
	Button,
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui'
import { cn } from '@/lib/utils.ts'
import { ResolveDialog } from '@/modules/merchant/reports/resolve-dialog.tsx'
import { TransactionDetail } from '@/modules/merchant/reports/transaction-detail-dialog.tsx'
import { ACH_RETURN_CODE } from '@/utils/codes.ts'
import { dateTimeFormatter } from '@/utils/dates.ts'

const columnHelper = createColumnHelper<DeclinedTransactions>()

export function ReturnsReport({ className }: { className?: string }) {
	const { data: returnsData, isLoading: reportLoading } = useReturns()
	const { mutate: createReturn, isPending: isCreatingReturn } = useCreateReturn()
	const { mutate: resolveReturn } = useResolveReturn()
	const [transactionDetails, setTransactionDetails] = useState<DeclinedTransactions | null>(null)
	const [openResolveDialog, setOpenResolveDialog] = useState(false)
	const [openTransactionDetailDialog, setOpenTransactionDetailDialog] = useState(false)

	const renderExpandedRow = useCallback((row: Row<DeclinedTransactions>) => {
		return (
			<TableRow className={cn(!row.getIsExpanded() && 'border-1')}>
				<TableCell
					className="p-0"
					colSpan={columns.length}
				>
					<div
						className={cn('grid transition-all')}
						style={{ gridTemplateRows: row.getIsExpanded() ? '1fr' : '0fr' }}
					>
						<Table className="w-full caption-bottom text-sm">
							<TableHeader>
								<TableRow className="hover:bg-transparent">
									<TableHead className="h-12 px-4 text-left align-middle font-medium text-muted-foreground">
										Retry Date
									</TableHead>
									<TableHead className="h-12 px-4 text-left align-middle font-medium text-muted-foreground">
										Code
									</TableHead>
									<TableHead className="h-12 px-4 text-left align-middle font-medium text-muted-foreground">
										Status
									</TableHead>
								</TableRow>
							</TableHeader>
							<TableBody>
								{row.original.retries.map((retry) => (
									<TableRow
										key={retry.id}
										className="hover:bg-transparent"
									>
										<TableCell className="px-4">{dateTimeFormatter.format(new Date(retry.created_at))}</TableCell>
										<TableCell className="px-4">{retry.code || '-'}</TableCell>
										<TableCell className="px-4">
											<span className={'font-medium uppercase'}>{retry.status}</span>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</div>
				</TableCell>
			</TableRow>
		)
	}, [])

	const handleManuallyResolve = (data: DeclinedTransactions) => {
		setTransactionDetails(data)
		setOpenResolveDialog(true)
	}

	const handleViewDetails = (data: DeclinedTransactions) => {
		setTransactionDetails(data)
		setOpenTransactionDetailDialog(true)
	}

	const handleRetry = (id: string) => {
		createReturn({ purchaseId: id })
	}

	const canRetry = useCallback((transaction: DeclinedTransactions) => {
		const { reason_code, transaction_status, retry_count, retries } = transaction
		const isRetryableCode = ['R01', 'R09'].includes(reason_code)
		const hasRetriesLeft = retry_count < 2
		const isDeclined = transaction_status === 'DECLINED'
		const hasPendingRetry = retries.some((retry) => retry.status === 'pending')
		const returnDate = new Date(transaction.date_time_of_approval)
		const daysSinceReturn = Math.floor((Date.now() - returnDate.getTime()) / (1000 * 60 * 60 * 24))
		const isWithinTimeWindow = daysSinceReturn <= 60
		return isRetryableCode && hasRetriesLeft && isDeclined && !hasPendingRetry && isWithinTimeWindow
	}, [])

	const confirmResolve = (purchase_id: string) => {
		resolveReturn({ purchaseId: purchase_id })
		setOpenResolveDialog(false)
	}

	const columns = useMemo(
		() => [
			columnHelper.display({
				id: 'expand',
				cell: ({ row }) => <DataTableColumnExpandButton row={row} />,
				meta: {
					className: cn('w-0 p-0 pl-1'),
				},
			}),
			columnHelper.accessor('date_time_created', {
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Date"
					/>
				),
				cell: ({ cell }) => dateTimeFormatter.format(new Date(cell.getValue())),
				enableSorting: false,
				meta: {
					className: cn('whitespace-nowrap'),
				},
			}),
			columnHelper.accessor('customer_name', {
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Customer"
					/>
				),
				enableSorting: false,
				meta: {
					className: cn('whitespace-nowrap'),
				},
			}),
			columnHelper.accessor('reason_code', {
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Code"
					/>
				),
				enableSorting: false,
				meta: {
					className: 'text-center',
				},
			}),
			columnHelper.accessor(
				(row) => {
					const reason_code = row.reason_code as keyof typeof ACH_RETURN_CODE
					return ACH_RETURN_CODE[reason_code] ?? '-'
				},
				{
					id: 'description',
					enableSorting: false,
					header: ({ column }) => (
						<DataTableColumnHeader
							column={column}
							title="Description"
						/>
					),
				},
			),
			columnHelper.accessor('transaction_status', {
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Status"
					/>
				),
				maxSize: 200,
				cell: ({ row }) => {
					return <span className={'font-medium capitalize'}>{row.original.transaction_status}</span>
				},
				enableSorting: false,
			}),
			columnHelper.accessor((row) => `${row.purchase_id}-${row.retry_count}`, {
				id: 'retries',
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Retry"
					/>
				),
				cell: ({ row }) => {
					const transaction = row.original
					const maxRetries = 2
					const retriesLeft = maxRetries - transaction.retry_count
					const hasPostedRetry = transaction.retries.some((retry) => retry.status === 'POSTED')

					if (transaction.reason_code !== 'R01' && transaction.reason_code !== 'R09') {
						return null
					}

					if (hasPostedRetry) {
						return (
							<Button
								variant="outline"
								disabled={true}
							>
								Resolved
							</Button>
						)
					}

					if (!canRetry(transaction)) {
						return (
							<Button
								variant="outline"
								disabled={true}
							>
								{retriesLeft <= 0 ? 'No Retries Left' : `Retry (${retriesLeft}/${maxRetries} Tries Left)`}
							</Button>
						)
					}

					return (
						<Button
							disabled={isCreatingReturn}
							onClick={() => handleRetry(transaction.purchase_id)}
							variant="secondary"
							className={cn(
								'bg-orange-500 text-white hover:bg-orange-600',
								isCreatingReturn && 'cursor-not-allowed opacity-50',
							)}
						>
							Retry ({retriesLeft}/{maxRetries} Tries Left)
						</Button>
					)
				},
				enableSorting: false,
			}),
			columnHelper.display({
				id: 'actions',
				cell: ({ row }) => {
					const transaction = row.original
					let shouldButtonBeDisabled = false
					if (transaction.reason_code !== 'R01' && transaction.reason_code !== 'R09') {
						shouldButtonBeDisabled = true
					}
					return (
						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button
									variant="outline"
									size="icon"
									className="h-8 w-8 rounded-md p-5"
								>
									<EllipsisVertical />
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end">
								<DropdownMenuItem
									disabled={shouldButtonBeDisabled}
									onClick={() => handleManuallyResolve(row.original)}
								>
									<Pen className="mr-2 h-4 w-4" />
									Manually Resolve
								</DropdownMenuItem>
								<DropdownMenuItem onClick={() => handleViewDetails(row.original)}>
									<FileText className="mr-2 h-4 w-4" />
									View Transaction Details
								</DropdownMenuItem>
							</DropdownMenuContent>
						</DropdownMenu>
					)
				},
			}),
		],
		[handleRetry, isCreatingReturn],
	)

	return (
		<div
			data-testid="transactions-report"
			className={cn('space-y-2 rounded-lg bg-card p-4', className)}
		>
			<DataTable
				isLoading={reportLoading}
				columns={columns}
				data={returnsData ?? []}
				getRowCanExpand={() => true}
				renderExpandedRow={renderExpandedRow}
			/>
			{transactionDetails && (
				<ResolveDialog
					onResolve={confirmResolve}
					onCancel={() => setOpenResolveDialog(false)}
					transactionDetails={transactionDetails}
					open={openResolveDialog}
					onOpenChange={setOpenResolveDialog}
				/>
			)}
			{transactionDetails && (
				<TransactionDetail
					onCancel={() => setOpenTransactionDetailDialog(false)}
					transactionDetails={transactionDetails}
					open={openTransactionDetailDialog}
					onOpenChange={setOpenTransactionDetailDialog}
				/>
			)}
		</div>
	)
}
