import { useQuery } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'

import { Nullable } from '@/@types/nullable'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

async function getIsValidAccount(httpInstance: KyInstance, SynAccountID: string) {
	try {
		const response = await httpInstance.get(`external_accounts/validate_account/${SynAccountID}`)
		return response.json<{ isValid: boolean }>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}

export function useIsValidAccount(SynAccountID: string, enabled = true) {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	return useQuery<Nullable<{ isValid: boolean }>, Error, Nullable<{ isValid: boolean }>>({
		queryKey: ['isValidAccount', SynAccountID],
		queryFn: () => getIsValidAccount(paymentServiceClient.httpInstance, SynAccountID),
		enabled: !isLoading && isAuthenticated && enabled,
	})
}
