import { useSearchParams } from 'react-router-dom'

import { useAccounts } from '@/api/hooks/use-accounts'
import { useEcommercePurchase } from '@/api/hooks/use-ecommerce-purchase'
import BodyClassName from '@/components/body-class-name'
import { ErrorDisplay } from '@/components/error-display'
import { Loading } from '@/components/loading'
import NeedHelpLink from '@/components/need-help-link'
import { PaymentSuccess } from '@/components/payment-success'
import { Button } from '@/components/ui'
import { EcommerceCheckout } from '@/modules/consumer/ecommerce/ecommerce-checkout'
import { dayjs } from '@/utils/dates'

export const EcommercePurchasePage = () => {
	return (
		<BodyClassName className="bg-white">
			<div className="container mx-auto h-full max-w-screen-sm px-4 pb-4 pt-4 md:pt-12">
				<EcommercePurchasePageContent />
			</div>
		</BodyClassName>
	)
}

export const EcommercePurchasePageContent = () => {
	const [params] = useSearchParams()

	const purchase = useEcommercePurchase({ id: params.get('sessionKey') || '' })
	const bankAccounts = useAccounts()

	const purchaseIsExpired = dayjs(purchase.data?.expiration).diff(dayjs()) <= 0

	if (purchase.isLoading || bankAccounts.isLoading) {
		return <Loading />
	}

	if (!purchase.data || purchaseIsExpired) {
		return (
			<div className="flex h-full w-full flex-col gap-2">
				<ErrorDisplay
					title={!purchase.data ? 'Order not found' : 'Order expired'}
					description="Restart the payment process"
					className="mt-auto"
				/>
				<NeedHelpLink className="mt-auto" />
				<Button
					variant="destructive"
					onClick={() => window.close()}
				>
					Close
				</Button>
			</div>
		)
	}

	if (purchase.data.purchase_id) {
		return (
			<PaymentSuccess
				amount={purchase.data.amount}
				timestamp={purchase.data.updated_at}
				onContinue={window.close}
				continueText="Close"
			/>
		)
	}

	return <EcommerceCheckout purchase={purchase.data} />
}
