import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { ErrorCode } from '@/api/errors.ts'
import { usePurchase } from '@/api/hooks/use-purchase'
import BodyClassName from '@/components/body-class-name'
import ThemeColor from '@/components/theme-color'
import { PaymentQr } from '@/pages/consumer/payment/payment-qr-page'
import { useConsumerPurchaseStore } from '@/store/consumer/consumerPurchaseStore'
import { usePurchaseWebSocket } from '../../../api/hooks/use-purchase-websocket'

export function PaymentPage() {
	const navigate = useNavigate()
	const setConfirmationStatus = useConsumerPurchaseStore((state) => state.setConfirmationStatus)

	const { addPurchase, isPending, isSuccess, isError, purchase, qrCodeExpired } = usePurchase()
	const isLoading = isPending || (!isSuccess && !isError)

	usePurchaseWebSocket(purchase?.ID, (purchaseWebsocketStatus) => {
		setConfirmationStatus(purchaseWebsocketStatus)
		if (purchaseWebsocketStatus.status === 'ACCEPTED') {
			navigate('/payment/confirmation')
		} else if (purchaseWebsocketStatus.status === ErrorCode.ErrInsufficientFunds) {
			navigate('/payment/insufficient-funds')
		} else {
			navigate('/payment/error')
		}
	})

	useEffect(() => {
		addPurchase()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<BodyClassName className="bg-primary-500">
			<ThemeColor className="bg-primary-500" />
			<div
				className="h-full w-full bg-blue-linear"
				data-testid="qr-code-container"
			>
				<div
					className="container flex h-full flex-col items-center justify-center p-6"
					data-testid="qr-code-container"
				>
					<PaymentQr
						isLoading={isLoading}
						purchase={purchase}
						qrCodeExpired={qrCodeExpired}
						addPurchase={addPurchase}
					/>
				</div>
			</div>
		</BodyClassName>
	)
}
