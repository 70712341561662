import { useMutation } from '@tanstack/react-query'
import { KyInstance } from 'ky'

import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

type LinkAccountRequest = {
	link_with: string
}

const postUserLinkAccount = async (httpInstance: KyInstance, request: LinkAccountRequest) => {
	return httpInstance.post('users/user/link-account', { json: request }).json()
}

export function useUserLinkAccount() {
	const { paymentServiceClient } = usePaymentService()

	return useMutation({
		mutationFn: async (request: LinkAccountRequest) => {
			return postUserLinkAccount(paymentServiceClient.httpInstance, request)
		},
	})
}
