import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import BodyClassName from '@/components/body-class-name'
import { PaymentSuccess } from '@/components/payment-success'
import ThemeColor from '@/components/theme-color'
import { useConsumerPurchaseStore } from '@/store/consumer/consumerPurchaseStore'

export const PaymentSuccessPage = () => {
	const navigate = useNavigate()
	const confirmationStatus = useConsumerPurchaseStore((state) => state.confirmationStatus)

	useEffect(() => {
		if (!confirmationStatus?.purchaseId) {
			navigate('/payment', { replace: true })
		}
	})

	if (!confirmationStatus) {
		return null
	}

	return (
		<BodyClassName className="bg-primary-50">
			<ThemeColor className="bg-primary-50" />

			<PaymentSuccess
				className="m-auto h-full max-w-lg p-4"
				amount={confirmationStatus.amount}
				timestamp={confirmationStatus.timestamp}
				publicId={confirmationStatus.publicId}
				onContinue={() => navigate('/dashboard')}
				continueText="Back To Home"
			/>
		</BodyClassName>
	)
}
