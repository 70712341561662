import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getExpandedRowModel,
	Row,
	TableOptions,
	TableState,
	useReactTable,
} from '@tanstack/react-table'
import { Fragment } from 'react'

import { Skeleton, Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui'
import { cn } from '@/lib/utils'
import { DataTablePagination } from './data-table-pagination'

type DataTableProps<TData> = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	columns: ColumnDef<TData, any>[]
	isLoading?: boolean
	data: TData[]
	rowCount?: number
	className?: string
	tableClassName?: string
	pagination?: {
		state: TableState['pagination']
		onChange: (pagination: TableState['pagination']) => void
		className?: string
	}
	getRowCanExpand?: TableOptions<TData>['getRowCanExpand']
	initialState?: TableOptions<TData>['initialState']
	renderExpandedRow?: (row: Row<TData>) => React.ReactNode
}

export function DataTable<TData>({
	columns,
	isLoading,
	data,
	rowCount,
	pagination,
	className,
	tableClassName,
	getRowCanExpand,
	initialState,
	renderExpandedRow,
}: DataTableProps<TData>) {
	const table = useReactTable<TData>({
		data,
		columns,
		initialState,
		state: {
			pagination: pagination?.state,
		},
		onPaginationChange: pagination
			? (updaterOrValue) => {
					if (typeof updaterOrValue === 'function') {
						pagination.onChange(updaterOrValue(pagination.state))
					} else {
						pagination.onChange(updaterOrValue)
					}
				}
			: undefined,
		getRowCanExpand,
		getCoreRowModel: getCoreRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		manualPagination: true,
		rowCount,
	})

	return (
		<div className={cn('space-y-4', className)}>
			<Table className={tableClassName}>
				<TableHeader>
					{table.getHeaderGroups().map((headerGroup) => (
						<TableRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => {
								const meta = header.column.columnDef.meta || {}
								return (
									<TableHead
										key={header.id}
										colSpan={header.colSpan}
										className={cn(meta.className, meta.headerClassName)}
									>
										{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
									</TableHead>
								)
							})}
						</TableRow>
					))}
				</TableHeader>
				<TableBody>
					{isLoading ? (
						Array.from({ length: pagination?.state.pageSize || 5 }).map((_, i) => (
							<TableRow key={i}>
								{table.getVisibleLeafColumns().map((column) => {
									const meta = column.columnDef.meta || {}
									return (
										<TableCell
											key={column.id}
											className={cn(meta.className, meta.cellClassName)}
										>
											<Skeleton className="h-5 w-full" />
										</TableCell>
									)
								})}
							</TableRow>
						))
					) : table.getRowModel().rows?.length ? (
						table.getRowModel().rows.map((row) => (
							<Fragment key={row.id}>
								<TableRow data-state={row.getIsSelected() && 'selected'}>
									{row.getVisibleCells().map((cell) => {
										const meta = cell.column.columnDef.meta || {}
										return (
											<TableCell
												key={cell.id}
												className={cn(meta.className, meta.cellClassName)}
											>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</TableCell>
										)
									})}
								</TableRow>
								{table.getCanSomeRowsExpand() && renderExpandedRow?.(row)}
							</Fragment>
						))
					) : (
						<TableRow>
							<TableCell
								colSpan={columns.length}
								className="h-24 text-center"
							>
								No results.
							</TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>

			{pagination && (
				<DataTablePagination
					table={table}
					className={pagination.className}
				/>
			)}
		</div>
	)
}
