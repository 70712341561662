import { Link } from 'react-router-dom'

import { ErrorWrapper } from '@/components/error/error-wrapper.tsx'
import { Button } from '@/components/ui'

export const PaymentCancelled = () => {
	return (
		<ErrorWrapper
			title="Transaction Cancelled"
			description="The transaction was cancelled."
		>
			<Button
				asChild
				variant="destructive"
				className="w-full"
			>
				<Link to="/dashboard">Back To Home</Link>
			</Button>
		</ErrorWrapper>
	)
}
