import { useMutation, useQueryClient } from '@tanstack/react-query'
import { KyInstance } from 'ky'

import { Account } from 'src/@types/accounts'
import { useAlert } from 'src/lib/useAlert.tsx'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

async function makeAccountPrimary(httpInstance: KyInstance, accountId: string) {
	return await httpInstance
		.post('external_accounts/primary', {
			json: {
				SynAccountID: accountId,
			},
		})
		.json<string>()
}
export function useMakeAccountPrimary({ showSuccessSnackbar = true }: { showSuccessSnackbar?: boolean } = {}) {
	const { paymentServiceClient } = usePaymentService()
	const queryClient = useQueryClient()
	const { triggerSnackbar } = useAlert()

	return useMutation({
		mutationFn: async (accountId: string) => {
			return makeAccountPrimary(paymentServiceClient.httpInstance, accountId)
		},
		onMutate: (edited: string) => {
			const previousAccounts = queryClient.getQueryData(['accounts']) as Account[]
			const updatedAccounts = previousAccounts.map((acc) => ({
				...acc,
				IsPrimary: false,
			}))
			const index = updatedAccounts.findIndex((acc) => acc.SynAccountID === edited)
			if (index !== -1) {
				updatedAccounts[index] = {
					...updatedAccounts[index],
					IsPrimary: true,
				}
				queryClient.setQueryData(['accounts'], updatedAccounts)
			}
			if (showSuccessSnackbar) {
				triggerSnackbar('Primary account updated successfully', 'success')
			}
		},
		onSuccess: async () => {
			await queryClient.invalidateQueries({
				queryKey: ['purchase_power'],
			})
		},
		onError: (error) => {
			console.error(error)
		},
	})
}
