import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

import BodyClassName from '@/components/body-class-name'
import ThemeColor from '@/components/theme-color'
import { useLogoutOptions } from 'src/utils/logout-utils'

export function LogoutPage() {
	const { logout } = useAuth0()
	const logoutOptions = useLogoutOptions()
	useEffect(() => {
		logout(logoutOptions)
			.then(() => console.log('Logged out'))
			.catch((error) => console.error('Logout error', error))
	}, [logout, logoutOptions])
	return (
		<BodyClassName className="bg-neutral-50">
			<ThemeColor className="bg-neutral-50" />
		</BodyClassName>
	)
}
