import { Separator } from '@/components/ui'
import { cn } from '@/lib/utils'
import { Details, DetailsProps } from './details'

type DetailsCardProps = React.ComponentProps<'section'> & {
	title?: string
	details: DetailsProps['details']
}

export function DetailsCard({ details, title, className, ...props }: DetailsCardProps) {
	return (
		<section
			className={cn('w-full rounded-lg bg-primary-50 p-6', className)}
			{...props}
		>
			{title && (
				<>
					<h2 className="typography-label-large-prominent">{title}</h2>
					<Separator className="my-4 bg-primary-400" />
				</>
			)}

			<Details details={details} />
		</section>
	)
}
