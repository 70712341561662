import { DeclinedTransactions } from '@/@types/merchant'
import { Button, Dialog, DialogContent, DialogFooter, DialogHeader } from '@/components/ui'
import { dateTimeFormatter } from '@/utils/dates.ts'
import { formatCentsToCurrency } from '@/utils/money-utils.ts'

type ResolveDialogProps = {
	onResolve: (purchaseId: string) => void
	onCancel: () => void
	transactionDetails: DeclinedTransactions
	open: boolean
	onOpenChange: (open: boolean) => void
}

export const ResolveDialog = ({ onResolve, onCancel, transactionDetails, open, onOpenChange }: ResolveDialogProps) => {
	return (
		<Dialog
			open={open}
			onOpenChange={onOpenChange}
		>
			<DialogContent>
				<DialogHeader>
					<h1 className="typography-title-large text-center">Are You Sure?</h1>
					<h2 className="typography-body-large text-center">Are you sure you want to resolve this transaction?</h2>
				</DialogHeader>
				<div className="w-full rounded-sm bg-primary-50 px-md py-lg">
					<h2 className="typography-label-large-prominent">Transaction Details</h2>
					<div className="my-4 h-[1px] w-full bg-primary-400" />
					<div className="grid grid-cols-2 gap-x-md gap-y-sm">
						<span className="typography-label-large-prominent">Transaction ID</span>
						<span className="typography-body-medium">{transactionDetails.public_id}</span>
						<span className="typography-label-large-prominent">Transaction Date</span>
						<span className="typography-body-medium">
							{dateTimeFormatter.format(new Date(transactionDetails.date_time_created))}
						</span>
						<span className="typography-label-large-prominent">Total Price</span>
						<span className="typography-body-medium">{formatCentsToCurrency(transactionDetails.base_amount)}</span>
						<span className="typography-label-large-prominent">Customer Name</span>
						<span className="typography-body-medium">{transactionDetails.customer_name}</span>
					</div>
				</div>
				<DialogFooter>
					<div className="grid w-full grid-cols-2 gap-md">
						<Button
							variant={'outline'}
							className="w-full"
							onClick={onCancel}
							type="button"
						>
							Cancel
						</Button>
						<Button
							className="w-full"
							onClick={() => onResolve(transactionDetails.purchase_id)}
							type="button"
						>
							Yes, resolve
						</Button>
					</div>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}
