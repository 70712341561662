import { zodResolver } from '@hookform/resolvers/zod'
import { UserPlus } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

import { useCreateBusinessUser } from '@/api/hooks/use-create-business-user'
import {
	Button,
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
	Form,
	FormComponent,
	Input,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui'
import { getNameFromRole, getNameFromRoles, getRoleLabel, MyRole, roleMappings } from '@/constants/my-role.ts'
import { usePermissions } from '@/hooks/use-permissions.ts'

const formSchema = z.object({
	email: z.string().email({ message: 'Please enter a valid email address' }),
	username: z.string().min(3, { message: 'Username must be at least 3 characters' }),
	password: z
		.string()
		.min(8, { message: 'Password must be at least 8 characters' })
		.regex(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]).+$/, {
			message:
				'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*)',
		}),
	role: z.string().min(1, { message: 'Please select a role' }),
})

type FormValues = z.infer<typeof formSchema>

export function AddUserDialog() {
	const [open, setOpen] = useState(false)
	const { createBusinessUserMutation } = useCreateBusinessUser()
	const { decodedToken } = usePermissions()
	const businessUserRole = getNameFromRoles(decodedToken?.paybotic_banking_roles)
	const isAdminStore = businessUserRole === getNameFromRole(MyRole.AdminStore)

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: '',
			username: '',
			password: '',
			role: '',
		},
	})

	const onSubmit = async (values: FormValues) => {
		try {
			createBusinessUserMutation.mutate(values)
			setOpen(false)
			form.reset()
		} catch (error) {
			console.error(`Failed to create business user: ${error}`)
		}
	}

	useEffect(() => {
		if (!open && (form.formState.isDirty || Object.keys(form.formState.errors).length > 0)) {
			form.reset()
		}
	}, [open, form, form.formState.isDirty, form.formState.errors])

	const getAvailableRoles = useMemo(() => {
		const roles = Array.from(roleMappings.keys()).filter((role) => role !== MyRole.Consumer)

		// AdminStore cannot assign AdminAccount role
		if (isAdminStore) {
			return roles.filter((role) => role !== MyRole.AdminAccount)
		}

		return roles
	}, [isAdminStore])

	return (
		<Dialog
			open={open}
			onOpenChange={setOpen}
		>
			<DialogTrigger asChild>
				<Button>
					<UserPlus className="mr-2 h-4 w-4" />
					Add User
				</Button>
			</DialogTrigger>
			<DialogContent aria-describedby={undefined}>
				<DialogHeader>
					<DialogTitle className="mb-4 text-center">
						<p className="typography-title-large">Add New User</p>
					</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form
						data-testid="merchant-add-user-form"
						onSubmit={form.handleSubmit(onSubmit)}
						className="space-y-4"
					>
						<FormComponent
							control={form.control}
							name="email"
							label="Email"
							render={({ field }) => (
								<Input
									type="email"
									placeholder="Enter email address"
									{...field}
								/>
							)}
						/>
						<FormComponent
							control={form.control}
							name="username"
							label="Username"
							render={({ field }) => (
								<Input
									placeholder="Enter a username"
									{...field}
								/>
							)}
						/>
						<FormComponent
							control={form.control}
							name="password"
							label="Password"
							render={({ field }) => (
								<Input
									type="password"
									placeholder="Enter a password"
									{...field}
								/>
							)}
						/>

						<FormComponent
							control={form.control}
							name="role"
							label="Role"
							render={({ field }) => (
								<Select
									onValueChange={field.onChange}
									value={field.value || undefined}
									name={field.name}
								>
									<SelectTrigger>
										<SelectValue placeholder="Select a role" />
									</SelectTrigger>
									<SelectContent>
										{getAvailableRoles.map((role) => (
											<SelectItem
												key={role}
												value={getNameFromRole(role)}
											>
												{getRoleLabel(role)}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							)}
						/>

						<DialogFooter className="gap-2 sm:gap-0">
							<Button
								className="w-full"
								type="button"
								variant="outline"
								onClick={() => setOpen(false)}
							>
								Cancel
							</Button>
							<Button
								className="w-full"
								disabled={createBusinessUserMutation.isPending}
								type="submit"
							>
								Add User
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	)
}
