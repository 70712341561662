export const ACH_RETURN_CODE = {
	R01: 'Insufficient Funds',
	R02: 'Account Closed',
	R03: 'No Account/Unable to Locate Account',
	R04: 'Invalid Account Number',
	R05: 'Unauthorized Debit to Consumer Account Using Corporate SEC Code',
	R07: 'Authorization Revoked by Customer',
	RO8: 'Payment Stopped',
	R09: 'Uncollected Funds',
	R10: 'Customer Advises Not Authorized',
	R12: 'Branch Sold to Another DFI',
	R16: 'Account Frozen',
	R20: 'Non-Transaction Account',
	R24: 'Duplicate Entry',
	R29: 'Corporate Customer Advises Not Authorized',
	R31: 'Permissible Return',
	R38: 'Stop Payment on Source Document',
	R39: 'Improper Source Document',
	R50: 'State Law Affecting RCK Acceptance',
	R51: 'Item Related to RCK Entry is Ineligible or Improper',
} as const
