import { LogoutOptions } from '@auth0/auth0-react'

import { usePermissionsStore } from '../store/permissions-store'

export const useLogoutOptions = (): LogoutOptions => {
	const isConsumer = usePermissionsStore((state) => state.isConsumer)
	const loginPath = isConsumer ? window.location.origin : `${window.location.origin}/merchant`

	return {
		logoutParams: {
			returnTo: loginPath,
		},
	}
}
