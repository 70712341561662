import { Fragment } from 'react'

import { cn } from '@/lib/utils'

export type Detail = {
	label: string
	value?: string | null
}

export type DetailsProps = React.ComponentProps<'dl'> & {
	details: Detail[]
}

export function Details({ details, className, ...props }: DetailsProps) {
	return (
		<dl
			className={cn('grid grid-cols-[max-content_1fr] gap-x-4 gap-y-2', className)}
			{...props}
		>
			{details.map(({ label, value }) =>
				value ? (
					<Fragment key={label}>
						<dt className="typography-label-large-prominent">{label}</dt>
						<dd
							className="typography-body-medium"
							data-testid={`order-details-value-${label.toLowerCase().replaceAll(' ', '-')}`}
						>
							{value}
						</dd>
					</Fragment>
				) : null,
			)}
		</dl>
	)
}
