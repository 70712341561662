import { Route } from 'react-router-dom'

import AuthenticationGuard from '@/components/authentication-guard'
import { Auth0LinkAccountProvider } from '@/lib/auth0-link-account/auth0-link-account-provider'
import { RegistrationPage } from '@/pages/consumer/registration-page'
import LandingPage from '@/pages/landing-page'
import { LoginPage } from '@/pages/login-page'
import MerchantLandingPage from '@/pages/merchant/merchant-landing-page'

export const publicRoutes = () => {
	return (
		<>
			<Route
				path="/"
				element={<LandingPage />}
			/>
			<Route
				path="/login"
				element={<LoginPage />}
			/>
			<Route
				path="/merchant"
				element={<MerchantLandingPage />}
			/>
			<Route
				path="/registration"
				element={
					<Auth0LinkAccountProvider>
						<AuthenticationGuard component={RegistrationPage} />
					</Auth0LinkAccountProvider>
				}
			/>
		</>
	)
}
