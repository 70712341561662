import { Table } from '@tanstack/react-table'

import {
	Pagination,
	PaginationButton,
	PaginationContent,
	PaginationEllipsis,
	PaginationItem,
	PaginationNext,
	PaginationPrevious,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui'
import { cn } from '@/lib/utils'

type DataTablePaginationProps<TData> = {
	table: Table<TData>
	className?: string
}

export function DataTablePagination<TData>({ table, className }: DataTablePaginationProps<TData>) {
	const currentPage = table.getState().pagination.pageIndex + 1
	const totalPages = table.getPageCount()

	const pageButtons: number[] = []
	let previousPage = Math.max(currentPage === 1 ? 0 : currentPage === totalPages ? currentPage - 3 : currentPage - 2, 0)

	for (let i = 0; i < 3; i++) {
		const nextPage = previousPage + 1

		if (nextPage > 0 && nextPage <= totalPages && nextPage !== previousPage) {
			pageButtons.push(nextPage)
			previousPage = nextPage // Update for the next iteration
		}
	}

	return (
		<div className={cn('flex items-center justify-between px-2', className)}>
			<div className="flex flex-1 flex-wrap items-center justify-between gap-4">
				<div className="flex flex-1 items-center justify-between space-x-2 sm:justify-start">
					<p className="whitespace-nowrap text-sm font-medium">Rows per page</p>
					<Select
						value={`${table.getState().pagination.pageSize}`}
						onValueChange={(value) => {
							table.setPageSize(Number(value))
						}}
					>
						<SelectTrigger className="h-8 w-[70px]">
							<SelectValue placeholder={table.getState().pagination.pageSize} />
						</SelectTrigger>
						<SelectContent side="top">
							{[10, 20, 30, 40, 50].map((pageSize) => (
								<SelectItem
									key={pageSize}
									value={`${pageSize}`}
								>
									{pageSize}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
				</div>

				<Pagination className="w-auto">
					<PaginationContent>
						<PaginationItem>
							<PaginationPrevious
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}
							/>
						</PaginationItem>
						<div className="hidden md:flex">
							<PaginationItem className={cn((pageButtons.length === 0 || pageButtons[0] === 1) && 'hidden')}>
								<PaginationEllipsis />
							</PaginationItem>
							{pageButtons.map((page) => (
								<PaginationItem key={page}>
									<PaginationButton
										isActive={page === currentPage}
										onClick={() => table.setPageIndex(page - 1)}
									>
										{page}
									</PaginationButton>
								</PaginationItem>
							))}
							<PaginationItem
								className={cn(
									(pageButtons.length === 0 || pageButtons[pageButtons.length - 1] === totalPages) && 'hidden',
								)}
							>
								<PaginationEllipsis className={cn(pageButtons[pageButtons.length - 1] === totalPages && 'invisible')} />
							</PaginationItem>
						</div>
						<PaginationItem className="block md:hidden">
							<span className="px-2 text-sm text-muted-foreground">
								{currentPage} / {totalPages}
							</span>
						</PaginationItem>
						<PaginationItem>
							<PaginationNext
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}
							/>
						</PaginationItem>
					</PaginationContent>
				</Pagination>
			</div>
		</div>
	)
}
