import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { HTTPError, KyInstance } from 'ky'

import { useAlert } from '@/lib/useAlert.tsx'
import { DeclinedTransactions } from '../../@types/merchant'
import { Nullable } from '../../@types/nullable'
import { usePaymentService } from '../services/providers/payment-service-provider.tsx'

async function getReturns(httpInstance: KyInstance) {
	try {
		const response = await httpInstance.get('retries')
		return response.json<DeclinedTransactions[]>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}

export function useReturns() {
	const { paymentServiceClient, isLoading, isAuthenticated } = usePaymentService()
	return useQuery<Nullable<DeclinedTransactions[]>, Error, Nullable<DeclinedTransactions[]>>({
		queryKey: ['returns'],
		queryFn: () => getReturns(paymentServiceClient.httpInstance),
		enabled: !isLoading && isAuthenticated,
	})
}

async function createReturn(httpInstance: KyInstance, purchaseId: string) {
	try {
		const response = await httpInstance.post(`retries/trigger/${purchaseId}`)
		return response.json<void>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}

export function useCreateReturn() {
	const { paymentServiceClient } = usePaymentService()
	const { triggerSnackbar } = useAlert()
	const queryClient = useQueryClient()

	return useMutation<void | null, Error, { purchaseId: string }, string>({
		mutationFn: async ({ purchaseId }) => {
			return createReturn(paymentServiceClient.httpInstance, purchaseId)
		},
		onSuccess: async () => {
			triggerSnackbar('Return created', 'success')
			await queryClient.invalidateQueries({
				queryKey: ['returns'],
			})
		},
		onError: (error) => {
			console.error(error)
			triggerSnackbar('Failed to create return', 'error')
		},
	})
}

async function resolveReturn(httpInstance: KyInstance, purchaseId: string) {
	try {
		const response = await httpInstance.post(`retries/resolve/${purchaseId}`)
		return response.json<void>()
	} catch (e) {
		if (e instanceof HTTPError && e.response.status === 404) {
			return null
		}
		throw e
	}
}

export function useResolveReturn() {
	const { paymentServiceClient } = usePaymentService()
	const { triggerSnackbar } = useAlert()
	const queryClient = useQueryClient()

	return useMutation<void | null, Error, { purchaseId: string }, string>({
		mutationFn: async ({ purchaseId }) => {
			return resolveReturn(paymentServiceClient.httpInstance, purchaseId)
		},
		onSuccess: async () => {
			triggerSnackbar('Return solved', 'success')
			await queryClient.invalidateQueries({
				queryKey: ['returns'],
			})
		},
		onError: (error) => {
			console.error(error)
			triggerSnackbar('Failed to resolve return', 'error')
		},
	})
}
