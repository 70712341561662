import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import NeedHelpLink from '@/components/need-help-link'
import { Form, Input, useFormComponentControlled } from '@/components/ui'
import { useAuth0LinkAccount } from '@/lib/auth0-link-account/index.tsx'
import { RegistrationFormLayout } from './RegistrationFormLayout.tsx'
import { RegistrationLayout } from './RegistrationLayout.tsx'

const verifyEmailSchema = z.object({
	email: z.string().email(),
})

type VerifyEmailFormData = z.infer<typeof verifyEmailSchema>

export default function VerifyEmail() {
	const auth0LinkAccount = useAuth0LinkAccount()

	const form = useForm<VerifyEmailFormData>({ resolver: zodResolver(verifyEmailSchema) })
	const FormComponent = useFormComponentControlled(form.control)

	const onSubmit = async (data: VerifyEmailFormData) =>
		auth0LinkAccount?.loginWithRedirect({
			authorizationParams: {
				connection: 'email',
				login_hint: data.email,
				audience: import.meta.env.VITE_AUTH0_AUDIENCE,
			},
		})

	return (
		<RegistrationLayout
			title="Email Verification"
			description="Verify your email to get started."
		>
			<Form {...form}>
				<RegistrationFormLayout
					isSubmitting={form.formState.isSubmitting}
					onSubmit={form.handleSubmit(onSubmit)}
					buttonChildren="Verify Email"
				>
					<FormComponent
						required
						name="email"
						label="Email"
						render={({ field }) => (
							<Input
								{...field}
								placeholder="Email"
							/>
						)}
					/>
					<NeedHelpLink className="mt-2" />
				</RegistrationFormLayout>
			</Form>
		</RegistrationLayout>
	)
}
