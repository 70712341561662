import { useMutation, useQueryClient } from '@tanstack/react-query'
import { KyInstance } from 'ky'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { useBusinessUserByAuthId } from '@/api/hooks/use-business-user-by-auth-id.ts'
import { usePaymentService } from '@/api/services/providers/payment-service-provider.tsx'
import { Loading } from '@/components/loading'
import { ErrorPage } from '../error-page'

export type Business = {
	id: number
	BusinessName: string
	SynBusinessId: string
	KybStatus: string
	CreatedAt: Date
	UpdatedAt: Date
}

const createBusiness = async (httpInstance: KyInstance) => {
	return await httpInstance.post('businesses').json<Business>()
}

export const RegistrationPage = () => {
	const queryClient = useQueryClient()
	const { paymentServiceClient } = usePaymentService()
	const navigate = useNavigate()
	const effectRan = useRef(false)
	const { refetch } = useBusinessUserByAuthId()
	const createBusinessMutation = useMutation({
		mutationFn: () => {
			return createBusiness(paymentServiceClient.httpInstance)
		},
		onSuccess: async (data: Business) => {
			queryClient.setQueryData<Business>(['business'], data)
			await refetch()
			navigate('/merchant/home')
		},
	})
	useEffect(() => {
		if (effectRan.current) {
			return
		}
		effectRan.current = true
		createBusinessMutation.mutate()
	}, [])

	if (createBusinessMutation.isError) {
		return <ErrorPage />
	}

	return <Loading />
}
