import { LandmarkIcon, Loader2, PlusIcon } from 'lucide-react'
import { useCallback, useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { useAccounts } from '@/api/hooks/use-accounts'
import { EcommercePurchaseResponse, useAcceptEcommercePurchase } from '@/api/hooks/use-ecommerce-purchase'
import { useIsValidAccount } from '@/api/hooks/use-isValid-account'
import { useMakeAccountPrimary } from '@/api/hooks/use-make-account-primary'
import { usePurchasePower } from '@/api/hooks/use-purchase-power'
import { DetailsCard } from '@/components/details-card'
import { SpendingLimitTooltip } from '@/components/spending-limit-tooltip'
import {
	Button,
	Label,
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
	Separator,
	Skeleton,
} from '@/components/ui'
import { cn } from '@/lib/utils'
import { formatCentsToCurrency } from '@/utils/money-utils'

export const EcommerceCheckout = ({
	purchase,
	className,
	...props
}: { purchase: EcommercePurchaseResponse } & React.ComponentProps<'div'>) => {
	const location = useLocation()

	const bankAccounts = useAccounts()
	const purchasePower = usePurchasePower()

	const primaryAccount = bankAccounts.data?.find((account) => account.IsPrimary)

	const isValidAccount = useIsValidAccount(primaryAccount?.SynAccountID || '', !!primaryAccount)

	const makeAccountPrimaryMutation = useMakeAccountPrimary({ showSuccessSnackbar: false })
	const acceptPurchaseMutation = useAcceptEcommercePurchase()

	const orderDetails = useMemo(
		() => [
			{ label: 'Order #', value: purchase.order_id },
			{ label: 'Amount', value: formatCentsToCurrency(purchase.amount) },
			{ label: 'Street', value: purchase.street },
			{ label: 'Unit', value: purchase.unit },
			{ label: 'City', value: purchase.city },
			{ label: 'State', value: purchase.state },
			{ label: 'Postal Code', value: purchase.postal_code },
		],
		[purchase],
	)

	const handleContinue = useCallback(async () => {
		acceptPurchaseMutation.mutateAsync({ id: purchase.id })
	}, [acceptPurchaseMutation, purchase.id])

	const returnTo = `${location.pathname}${location.search}`

	const purchasePowerIsLoading =
		makeAccountPrimaryMutation.isPending ||
		isValidAccount.isPending ||
		(isValidAccount.data?.isValid && purchasePower.isPending)

	const continueIsLoading = acceptPurchaseMutation.isPending || acceptPurchaseMutation.isSuccess

	const insufficientPurchasePower =
		!purchasePower.data?.purchase_power || purchase.amount > purchasePower.data.purchase_power

	return (
		<div
			className={cn('space-y-4', className)}
			{...props}
		>
			<h2 className="typography-title-large text-primary-900">Order details</h2>
			<DetailsCard details={orderDetails} />
			<Separator />
			<h2 className="typography-title-large text-primary-900">Payments</h2>

			<div className="space-y-1">
				<Label htmlFor="payment-method">Select Payment Method</Label>
				<Select
					defaultValue={primaryAccount?.ID.toString()}
					onValueChange={(selected) => {
						const selectedAccount = bankAccounts.data?.find((account) => account.ID === Number(selected))

						if (!selectedAccount) {
							return
						}

						makeAccountPrimaryMutation.mutate(selectedAccount.SynAccountID)
					}}
					disabled={!bankAccounts.data?.length}
				>
					<SelectTrigger
						id="payment-method"
						className={cn(isValidAccount.isFetched && !isValidAccount.data?.isValid && 'border-destructive')}
					>
						<div className="flex items-center gap-2">
							<LandmarkIcon />

							<SelectValue
								placeholder={
									bankAccounts.isLoading ? (
										<Skeleton className="typography-label-large h-5 w-44" />
									) : (
										'No bank accounts linked'
									)
								}
								className="justify-self-start"
							/>
						</div>
					</SelectTrigger>
					<SelectContent>
						{bankAccounts.data?.map((account) => (
							<SelectItem
								key={account.ID}
								value={String(account.ID)}
							>
								{account.VendorInstitutionName} - {account.LastFour}
							</SelectItem>
						))}
					</SelectContent>
				</Select>

				{!!bankAccounts.data?.length && purchasePowerIsLoading && (
					<Skeleton className="typography-label-large h-5 w-44" />
				)}

				{isValidAccount.isFetched && !isValidAccount.data?.isValid && (
					<p className="typography-label-large text-destructive">
						Account is not linked.
						<Button
							asChild
							variant="link"
							className="h-auto p-1 font-bold underline"
						>
							<Link
								to={{
									pathname: '/link-account',
									search: new URLSearchParams({
										returnTo,
										redirectTo: returnTo,
										vendorInstitutionID: primaryAccount?.VendorInstitutionID || '',
										vendorAccessToken: primaryAccount?.VendorAccessToken || '',
									}).toString(),
								}}
							>
								Link Account
							</Link>
						</Button>
					</p>
				)}

				{isValidAccount.isFetched && isValidAccount.data?.isValid && !purchasePower.isPending && (
					<p className="typography-label-large flex items-center text-muted-foreground">
						{formatCentsToCurrency(purchasePower.data?.purchase_power)} spending limit{' '}
						<span className="px-1">
							<SpendingLimitTooltip />
						</span>
					</p>
				)}
			</div>

			<Button
				variant="outline"
				asChild
			>
				<Link
					to={{
						pathname: '/link-account',
						search: new URLSearchParams({ returnTo, redirectTo: returnTo }).toString(),
					}}
				>
					<PlusIcon /> Add New Banking Account
				</Link>
			</Button>

			<Separator />
			<p className="typography-body-medium text-center">
				By using Paybotic Financial, you agree to the{' '}
				<a
					href="https://payboticfinancial.com/terms-and-conditions/"
					target="_blank"
					rel="noopener"
					className="underline"
				>
					Terms and Conditions of Service
				</a>
			</p>

			<Button
				size="lg"
				className="w-full text-center"
				onClick={handleContinue}
				disabled={acceptPurchaseMutation.isPending || acceptPurchaseMutation.isSuccess || insufficientPurchasePower}
			>
				{continueIsLoading && <Loader2 className="h-4 w-4 animate-spin" />}
				Continue
			</Button>
		</div>
	)
}
