import { Auth0Role } from '@/api/hooks/use-business-user-by-auth-id'

export enum MyRole {
	Consumer = 'pc:consumer',
	AdminAccount = 'pc:account_admin',
	AdminStore = 'pc:store_admin',
	ViewAccount = 'pc:account_viewer',
	ViewStore = 'pc:store_viewer',
}

export const roleMappings = new Map<MyRole, string>([
	[MyRole.Consumer, 'Consumer'],
	[MyRole.AdminAccount, 'Account Admin'],
	[MyRole.AdminStore, 'Store Admin'],
	[MyRole.ViewAccount, 'Account Viewer'],
	[MyRole.ViewStore, 'Store Viewer'],
])

export const getRoleLabel = (role: MyRole): string => roleMappings.get(role) || 'Unknown Role'

export const getRoleLabelFromName = (role: string): string => getRoleLabel(`pc:${role}` as MyRole)

const isPCRole = (role: string): boolean => role.startsWith('pc:')

export const getNameFromRole = (role: string): string => {
	return isPCRole(role) ? role.replace('pc:', '') : ''
}

export const getNameFromRoles = (roles: string[] | null | undefined): string => {
	for (const role of roles || []) {
		const name = getNameFromRole(role)
		if (name) {
			return name
		}
	}
	return ''
}

export const getNameFromAuthRoles = (roles: Pick<Auth0Role, 'name'>[] | null): string => {
	return getNameFromRoles(roles?.map((role) => role.name).filter((i) => !!i))
}
