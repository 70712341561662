import BodyClassName from '@/components/body-class-name.tsx'
import { ErrorDisplay } from '@/components/error-display.tsx'
import ThemeColor from '@/components/theme-color.tsx'

type Props = {
	children: React.ReactNode
	title?: string
	description?: string
}

export function ErrorWrapper({ children, title, description }: Props) {
	return (
		<BodyClassName className="bg-neutral-50">
			<ThemeColor className="bg-neutral-50" />
			<div className="m-auto flex h-full max-w-lg flex-col p-4 text-center">
				<ErrorDisplay
					title={title}
					description={description}
					className="my-auto"
				/>
				{children}
			</div>
		</BodyClassName>
	)
}
