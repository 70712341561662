import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useRef } from 'react'
import { toast } from 'sonner'

import { useUserLinkAccount } from '@/api/hooks/use-user-link-account'
import { useAuth0LinkAccount } from '@/lib/auth0-link-account'

export function useLinkAuth0SecondaryAccount() {
	const auth0MainAccount = useAuth0()
	const auth0LinkAccount = useAuth0LinkAccount()

	const userLinkAccountMutation = useUserLinkAccount()

	const hasLinkedAccounts = useRef(false)

	useEffect(() => {
		if (hasLinkedAccounts.current) return

		if (!auth0LinkAccount.user || auth0MainAccount.user?.email_verified || userLinkAccountMutation.status !== 'idle')
			return

		const linkAccount = async () => {
			const linkAccountToken = await auth0LinkAccount.getAccessTokenSilently()
			userLinkAccountMutation.mutate(
				{ link_with: linkAccountToken },
				{
					onSuccess: () => auth0MainAccount.getAccessTokenSilently({ cacheMode: 'off' }),
					onError: () =>
						toast.error('Error', {
							description: 'Unable to verify email',
							action: {
								label: 'Close',
								onClick: () => {},
							},
						}),
				},
			)
		}

		linkAccount()
		hasLinkedAccounts.current = true
	}, [
		auth0LinkAccount,
		auth0MainAccount.user?.email_verified,
		auth0MainAccount.getAccessTokenSilently,
		userLinkAccountMutation,
		auth0MainAccount,
	])

	return auth0LinkAccount
}
